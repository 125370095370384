import React from 'react';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';
import { Container } from 'styled-bootstrap-components';

import Layout from '../components/Layout';

const Post = props => {
  const { data } = props;

  return (
    <Layout location={props.location}>
      <Helmet title={`${data.wordpressPost.title} - Eddy Herrera`} />
      <div className="home-banner">
        <Container>
          <div className="section-title mb-5">
            <h1>{data.wordpressPost.title}</h1>
          </div>
          <div dangerouslySetInnerHTML={{ __html: data.wordpressPost.content }} />
        </Container>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query PostQuery($slug: String!) {
    wordpressPost(slug: { eq: $slug }) {
      title
      slug
      content
    }
  }
`;

export default Post;
